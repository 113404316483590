.split-screen-container {
    display: flex;
    width: 100vw;
    margin: 0 auto;
    gap: 20px;
    height: 100vh;
}

.split-screen-left,
.split-screen-right {
    flex: 1;
    overflow-y: auto;
    height: 100%;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.single-screen-container {
    width: 100vw;
    margin: 0 auto;
} 