.blog-page .tags {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin: 3rem 0;
}

.blog-page .tags .tag {
    border: 1px solid var(--black);
    border-radius: 10px;
    color: var(--black);
    font-size: 1.5rem;
    padding: 0.25rem 1rem;
    transition: border-color 0.2s linear, background-color 0.2s linear, color 0.2s linear,;
}

.blog-page .tags .tag.active, .blog-page .tags .tag:hover {
    border-color: var(--red);
    background-color: var(--red);
    color: white;
}

.blog-page .articles-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin: 2rem 0;
}

.blog-page .articles-grid .article {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-basis: 500px;
}

.blog-page .articles-grid .article * {
    width: max-content;
}

.blog-page .articles-grid .article img {
    max-width: 100%;
}

.blog-page .articles-grid .article .writer {
    color: var(--red);
}

.blog-page .articles-grid .article .title {
    color: var(--black);
}

.blog-page .articles-grid .article .desc {
    color: var(--darkgrey);
}