.standard-module {
    background-color: white;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 8px;
}

.standard-module-screen-small {
    background-color: white;
    width: 96%;
    margin: 0 auto;
    margin-bottom: 30px;
    padding: 10px;
    border-radius: 8px;
}

.module-title {
    font-size: 32px;
    transform: translateY(-12px);

    /* font-family: ahroni; */
}

.input-container {
    position: relative;
    width: 100%;
}

.error-input {
    border-color: #ff4444;
}

.error-message {
    color: #ff4444;
    font-size: 0.8em;
    position: absolute;
    bottom: -20px;
    left: 0;
}

.feedback-section {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.feedback-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.submit-button {
    align-self: flex-end;
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #0056b3;
}