@import "../../globals.css";

.landing-stats {
    display: none;
    width: 100vw;
    margin: 50px auto;
}

.stats {
    color: var(--red);
}

.col {
    align-items: center;
}

.stats .col {
    position: relative;
}

.num {
    font-size: 50px;
    font-weight: 900;
    margin: 0;
}

.live {
    position: absolute;
    right: 0;
    top: 0;
}

.review {
    background-color: var(--grey);
    flex-basis: 300px;
    border-radius: 10px;
    padding: 10px 25px;
    position: relative;;
}
.signature {
    text-align: right;
}

.profile {
    position: absolute;
    left: -15px;
    bottom: -15px;
    font-size: 2em;
    height: 50px;
    width: 50px;
    /* color: var(--grey); */
    background-color: white;
    border-radius: 99px;
    border: 2px solid var(--red);
}