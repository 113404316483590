.about-timeline {
    width: 100vw;
    margin: 50px auto;
}

.about-timeline .section-content {
    position: relative;
    margin-top: 5rem;

}

.about-timeline .events {
    display: flex;
    flex-direction: column;
    gap: 5rem;
}

.about-timeline .events .event {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.about-timeline .events .event img {
    width: 7rem;
    height: 7rem;
    object-fit: contain;
    padding: 0.5rem;
    border: 2px solid var(--grey);
    border-radius: 4px;
    box-sizing: border-box;
    display: none; /* for mobile */
}

.about-timeline .events .event .date1, .about-timeline .events .event .date2-desktop {
    color: var(--red);
    width: 8rem;
    flex-shrink: 0;
    padding: 0.25rem;
}

.about-timeline .events .event .date1 {
    text-align: right;
    border-top: 3px dotted var(--black);
    padding: 0.5rem;
}

.about-timeline .events .event .date2-mobile {
    display: block; /* show on mobile */
}

.about-timeline .events .event .date2-desktop {
    text-align: left;
    padding: 0.5rem;
    display: none; /* hide on mobile */
}

.about-timeline .events .event .diamond-black, .about-timeline .events .event .diamond-red {
    font-size: 1.25rem;
    color: var(--black);
    position: absolute;
    transform: translate(-50%, calc(-50% + 1.5px));
    left: calc(8rem + 0.25rem); /* mobile: width of dots/date1 + half gap */
}

.about-timeline .events .event .diamond-red {
    font-size: 0.75rem;
    color: var(--red);
}

.about-timeline .events .event .right-container {
    border-top: 3px solid var(--black);
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.about-timeline .events .event .right-container .text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0.5rem 0;
    text-align: left;
    white-space: pre;
}

.about-timeline .events .event .right-container span a {
    color: var(--red);
}

.about-timeline .events h3{
    color: var(--red);
    /* width: max-content; */
    text-align: left;
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
}

.about-timeline .events p{
    text-wrap: wrap;
    text-align: left;
}


.about-timeline .line {
    /* container for vertical line in center of timeline */
    position: absolute;
    left: calc(8rem + 0.25rem); /* mobile: width of dots/date1 + half gap */
}

.about-timeline .line, .about-timeline .line .flag, .about-timeline .line .red-line, .about-timeline .line .black-line {
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    height: 100%;
}

.about-timeline .line .flag {
    top: -3rem;
    font-size: 2rem;
    color: var(--red);
}

.about-timeline .line .red-line {
    width: 0;
    border: 2px solid var(--red);
    height: 100%;
}

.about-timeline .line .black-line {
    width: 0;
    border: 2px solid var(--black);
    height: 100%;
}

@media only screen and (min-width: 70rem) {
    .about-timeline .events .event img {
        display: block; /* show on desktop */
    }

    .about-timeline .line, .about-timeline .events .event .diamond-black, .about-timeline .events .event .diamond-red {
        left: calc(7rem + 0.5rem + 8rem + 0.25rem); /* desktop: width of img + gap + dots/date1 + half gap */
    }

    .about-timeline .events .event .date2-mobile {
        display: none; /* hide on desktop */
    }

    .about-timeline .events .event .date2-desktop {
        display: block; /* show on desktop */
    }
}