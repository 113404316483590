@import "../../globals.css";

.landing-contact {
    background-image: url("../../../images/ambulance.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 0;
    background-position-y: 50%;
    background-position-x: 70%;
}

.landing-contact .content{
    color: white;
    display: flex;
    flex-direction: column;
}

/* .landing-contact h3 {
    color: white;
    font-family: 'montserrat';
    text-align: left;
    font-size: 36px;
    margin: 0;
} */

.landing-contact h2::after {
    background-color: white;
}

.landing-contact p, .landing-contact h3 {
    width: max-content;
}

.landing-contact p.desc {
    margin: 20px 0px;
    font-size: 1.25em;
    text-align: left;
}

.landing-contact .form {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    gap: 25px;
}

.landing-contact .row {
    display: flex;
    flex-direction: row;
    justify-content: start;
    flex-wrap: wrap;
    gap: 25px;
}

.landing-contact .name input {
    flex-basis: 200px;
    flex-grow: 1;
}

.landing-contact .location input {
    flex-basis: 500px;
    flex-grow: 1;
}

.landing-contact input, .landing-contact input:active {
    border: 2px solid white;
    outline: 2px solid var(--white);
    border-radius: 5px;
    background-color: rgba(102, 102, 102, 0.5);
    height: max-content;
    padding: 5px;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    transition: border-color 0.15s linear, outline-color 0.15s linear;
}

.landing-contact input:focus, .landing-contact select:focus, .landing-contact select:focus-visible {
    border-color: var(--red);
    outline-color: var(--red);
}

.landing-contact input::placeholder {
    color: var(--grey);
}

.landing-contact select {
    border: 2px solid white;
    border-radius: 5px;
    background: transparent;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: rgba(102, 102, 102, 0.5);
}

.landing-contact select option {
    background-color: var(--black);
    font-size: 16px;
    margin: 10px 20px;
}

.landing-contact, input.submit-btn {
    background-color: var(--red);
    color: white;
    border-color: var(--red);
    font-family: 'lato-bold';
    transition: background-color 0.15s linear, border-color 0.15s linear, color 0.15s linear;
}

.landing-contact input.submit-btn:hover, input.submit-btn:focus {
    background-color: white;
    border-color: white;
    color: var(--black);
}

.landing-contact .form .msg {
    height: 1em;
    color: white;
    font-family: "lato-bold-italic";
}
