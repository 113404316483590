.accordion-container {
    width: 100%;
    max-width: 56rem;
    margin: 0 auto;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.accordion-header {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--dark-grey);
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.accordion-header:hover {
    background-color: var(--medium-grey);
}

.accordion-title {
    font-size: 1.25rem;
    font-weight: bold;
}

.accordion-subtitle {
    font-size: 0.875rem;
    color: #4b5563;
    margin-top: 0.25rem;
}

.accordion-content {
    padding: 10px;
    background-color: var(--light-grey);
    border-radius: 5px;
    margin-top: 5px;
}

.accordion-item {
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    margin-bottom: 0.5rem;
}

.accordion-item-root {
    border-color: #60a5fa;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.accordion-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    cursor: pointer;
}

.accordion-item-header-root {
    background-color: #eff6ff;
}

.accordion-item-header-root:hover {
    background-color: #dbeafe;
}

.accordion-item-header-default {
    background-color: #f9fafb;
}

.accordion-item-header-default:hover {
    background-color: #f3f4f6;
}

.accordion-item-title-root {
    font-weight: bold;
    font-size: 1.125rem;
    color: #1d4ed8;
}

.accordion-item-title-default {
    font-weight: 500;
}

.accordion-item-content {
    padding: 0.75rem;
    border-top: 1px solid #e5e7eb;
}

.accordion-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
}

.array-container {
    padding-left: 1rem;
    border-left: 2px solid #86efac;
}

.nested-content {
    margin-left: 8px;
    padding: 12px;
    margin-top: 8px;
    margin-bottom: 8px;
    position: relative;
    border-radius: 4px;
}

.nested-content.depth-0 {
    background-color: rgba(96, 165, 250, 0.2);
}

.nested-content.depth-1 {
    background-color: rgba(52, 211, 153, 0.15);
}

.nested-content.depth-2 {
    background-color: rgba(245, 158, 11, 0.12);
}

.nested-content.depth-3 {
    background-color: rgba(239, 68, 68, 0.1);
}

.nested-content.depth-4 {
    background-color: rgba(139, 92, 246, 0.08);
}

.nested-content.depth-5 {
    background-color: rgba(236, 72, 153, 0.06);
}

.nested-content.depth-6 {
    background-color: rgba(20, 184, 166, 0.05);
}

.nested-content.depth-7 {
    background-color: rgba(249, 115, 22, 0.04);
}

.nested-content.depth-8 {
    background-color: rgba(59, 130, 246, 0.03);
}

.nested-content.depth-9 {
    background-color: rgba(16, 185, 129, 0.02);
}

.nested-content::before {
    content: '';
    position: absolute;
    left: -2px;
    top: 0;
    height: 100%;
    width: 2px;
    background: linear-gradient(to bottom, #94a3b8 0%, #94a3b8 100%);
    opacity: 0.8;
}

.nested-content-indent {
    border-left: 2px solid #d1d5db;
    padding-left: 0.5rem;
}

.button-container {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
}

.button {
    padding: 0.5rem 1rem;
    border: 1px solid #d1d5db;
    border-radius: 0.375rem;
}

.button:hover {
    background-color: #f3f4f6;
}

.button-primary {
    background-color: #dbeafe;
    border-color: #93c5fd;
}

.button-primary:hover {
    background-color: #bfdbfe;
}

.updated-fields {
    margin-top: 1rem;
    padding: 1rem;
    background-color: #f9fafb;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
}

.updated-fields-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.updated-fields-content {
    max-height: 10rem;
    overflow: auto;
}

.updated-field-item {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
}

.updated-field-path {
    font-weight: 500;
}

.element-value {
    color: var(--dark-grey);
    word-break: break-word;
}

.read-only-text {
    padding: 8px;
    display: inline-block;
    min-width: 200px;
    color: #333;
    font-size: 14px;
}

.data-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    min-width: 200px;
    background-color: white;
}

.data-input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

/* Add this to your AccordionListElement.css file */

.list-element-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .data-input {
    width: 100%;
    padding: 4px 8px;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    font-size: 0.9rem;
    background-color: #f9f9f9;
  }
  
  .data-input:focus {
    outline: none;
    border-color: #aaa;
    background-color: #fff;
  }
  
  .nested-content {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #e0e0e0;
  }
  
  .update-actions {
    margin-top: 20px;
    text-align: right;
    padding: 8px;
  }
  
  .update-button {
    padding: 8px 16px;
    background-color: #f3f3f3;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .update-button:hover {
    background-color: #e7e7e7;
  }