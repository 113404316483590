@import url("ListElement.css");

.rating-button {
    background-color: #0c7ffa;
    color: #fff;
    padding: 5px 10px;
    border: 1px solid #0c7ffa;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 6px;
}

.rating-button-sel {
    background-color: #fff;
    color: #0c7ffa;
    padding: 5px 10px;
    border: 1px solid #0c7ffa;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 6px;
}