.standard-box {
    background-color: #eee;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;
    border-radius: 10px;
    /* margin-top: 80px; */
    margin-bottom: 40px;
}

.standard-box-screen-small {
    background-color: #eee;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    border-radius: 10px;
    /* margin-top: 80px; */
    margin-bottom: 40px;
}

.box-title {
    /* font-size: 72px; */ /* defualt to h1 rem values */
    /* font-family: ahroni; */
    font-family: montserrat;
    width: 100%;
}

.box-button {
    background-color: #0c7ffa;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.box-spacer {
    margin-bottom: 80px;
    width: 100vw;
}