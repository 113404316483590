@import url("ListElement.css");

.list-element-read {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #DDD;
    border-radius: 5px;
    margin-bottom: 10px;
}

.list-element-finished {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #29ba46;
    border-radius: 5px;
    margin-bottom: 10px;
}

.report-number {
    font-weight: bold;
    margin-right: 10px;
}

.number-of-issues, .number-of-suggestions {
    font-weight: bold;
    margin-right: 10px;
    color: #e83838;
}

.report-type {
    /* flex-grow: 1; */
    margin-right: 10px;
}

.incident-time {
    font-weight: bold;
    /* flex-grow: 1; */
    margin-right: 10px;
}

.enter-button {
    background-color: #0c7ffa;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.invisible-button {
    background-color: #fff;
    color: #fff;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    visibility: hidden;
}