@import "../../globals.css";
/* @import "../../../fonts/fonts.css"; */

.landingTitle {
    /* background-color: var(--grey); */
    width: 100vw;
    margin: 50px auto;
}

.landingTitle .content.row{
    display: flex;
    flex-direction: row;
    /* flex-basis: 300px; */
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
}

.landingTitle .col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.landingTitle .col.text {
    flex-basis: 400px;
    flex-grow: 2;
}

.landingTitle .demo-btn {
    color: white;
    text-decoration: none;
    border: 2px solid var(--red);
    border-radius: 5px;
    padding: 5px 15px;
    background-color: var(--red);
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    transition: background-color 0.15s linear, color 0.15s linear;
}

.landingTitle .demo-btn:hover {
    background-color: transparent;
    color: var(--red);
}

.landingTitle .col.graphs {
    flex-basis: 400px;
    flex-grow: 1;
}

.landingTitle .col h1 {
    text-wrap: wrap;
    width: 100%;
}


.graphs img.front {
    width: 90%;
    height: auto;
}

/* .graphs img.back {
    width: 90%;
    position: absolute;
    top: 0;
    right: 0;
} */
