@import "../../globals.css";

.landing-intro {
    background-color: var(--grey);
    padding: 50px auto;
}

.landing-intro .content {
    padding: 50px 0;
    /* margin: 50px auto; */
    /* margin-top: 100px; */
}

.landing-intro .questions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 50px;
    margin: auto;
    margin-bottom: 80px;
    width: max-content;
    align-items: flex-start;
}

.landing-intro .question {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 1rem;
    text-align: left;
    flex-basis: 300px;
    flex-grow: 1;
    height: max-content;
}

.landing-intro .question h5 {
    font-size: 1.8rem;
    margin: 0;
}

.landing-intro .question p {
    font-size: 1rem;
}

.landing-intro .question p span {
    color: var(--red);
    font-weight: 700;
    text-decoration: underline;
}

.landing-intro h3 {
    font-size: 1.8em;
}

.landing-intro .features {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 75px;
}

.landing-intro .features .card {
    padding: 30px 10px;
    color: white;
    background-color: white;
    border-radius: 10px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 280px;
    gap: 1em;
}

.landing-intro .features .card * {
    margin: 0;
    color: var(--dark-grey);
}
.landing-intro .features .card h4 {
    font-size: 1.25rem;
    text-align: center;
}

.landing-intro .features .card p {
    text-align: center;
}

.landing-intro i {
    font-size: 4rem;
    color: var(--red);
}

h3 {
    color: var(--red);
}

