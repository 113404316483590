  .login-container {
    background-color: #eee;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 100px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  }

  .mfa-container {
    background-color: #eee;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 100px;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); */
  }
  
  .logo_login {
    width: 250px;
    height: 70px;
    margin-bottom: 20px;
    object-fit: contain;
    /* margin-right: -24px; */
  }
  
  .input-container {
    margin-bottom: 15px;
  }
  
  .email-input,
  .password-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .login-button {
    width: 100%;
    padding: 10px;
    background-color: #e83838;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  .mfa-button {
    width: 100%;
    padding: 10px;
    background-color: #e83838;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
  
  .error-popup {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }