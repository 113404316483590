@import "../../globals.css";

.landing-features {
    margin: 50px auto;
}

.landing-features.content .section-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.landing-features .qi, .landing-features .qa {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.landing-features .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 25px 0;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.landing-features .qi-row {
    flex-direction: row-reverse;
}

.landing-features .col, .landing-features .carousel {
    display: flex;
    flex-direction: column;
    flex-basis: 450px;
    flex-grow: 1;
    margin: 0 0;
    text-align: left;
    height: max-content;
    align-items: flex-start;
}

.landing-features .carousel {
    padding-top: 0; /* 0 for mobile, 95px for desktop to place under header logo */
}

.landing-features img  {
    max-width: 100%;
}

.landing-features .carousel .images-container {
    overflow-y: scroll;
    height: 500px;
    /* border: 2px solid var(--dark-grey); */
    /* border-radius: 10px; */
}

.landing-features .carousel .images-container::-webkit-scrollbar {
    width: 8px;
}

.landing-features .carousel .images-container::-webkit-scrollbar-track {
    background-color: var(--light-grey);
    border-radius: 10px;
}

.landing-features .carousel .images-container::-webkit-scrollbar-thumb {
    background-color: var(--med-grey);
    border-radius: 10px;
}

.landing-features .qi-carousel .images-container {
    overflow-y: auto;
    height: auto;
}

.landing-features .carousel img  {
    max-width: 100%;
    margin: auto;
    object-fit: cover;
    width: 100%;
}

.landing-features .logo {
    height: 70px;
    width: auto;
    object-fit: contain;
    margin: auto;
    max-width: 70vw;
    margin-bottom: 25px;
}
.landing-features .col .desc {
    text-align: justify;
}

.landing-features .col .features {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;
    margin: 20px auto;
    max-width: 500px; /* max width set to width of 3 icons, so there will never be one icon on its own row due to flex-grow */
}

.landing-features .col .features .feature {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    flex-basis: 120px;
    text-align: center;
    gap: 10px;
}


.landing-features .col .features .feature i {
    font-size: 3rem;
    color: var(--red);
}

.landing-features .col .features .feature p {
    font-size: 1rem;
}

.landing-features .checks {
    margin: 15px 0;
}

.landing-features .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
}

.landing-features .tags .tag {
    padding: 5px 10px !important;
    border-radius: 10px !important;
    margin: 0.25em;
    font-size: 1rem;
}

.landing-features hr {
    background-color: var(--black);
    height: 0.25rem;
    width: 500px;
    border-radius: 1em;
    border: none;
}

@media only screen and (min-width: 65.99rem) { /* manually found the breaking point, can probably calculate it better way */
    .landing-features .carousel {
        padding-top: 95px; /* 70px for logo, 25px for margin */
    }
}

.landing-features iframe {
    width: 100%;
    aspect-ratio: 16/9;
}