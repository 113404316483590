@import "../fonts/fonts.css";

:root {
    --red: #E83838;
    --blue: #0C7FFA;
    --light-grey: #EEE;
    --grey: #EEE;
    --med-grey: #CCC;
    --dark-grey: #222;
    --black: #222;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "lato", sans-serif;
    color: var(--black);
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

body * {
    max-width: 100%;
}

.content {
    width: 1600px;
    max-width: 90%;
    margin: auto;
}

.section-content {
    /* for mobile sections under each header, no extra margin needed */
    margin: 0 0px;
}

.col {
    display: flex;
    flex-direction: column;
}

.row {
    display: flex;
    flex-direction: row;
}


button {
    padding: 5px 15px;
    border-radius: 5px;
    width: max-content;
    /* border: 2px solid var(--black); */
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    cursor: pointer;
}

.blue-btn {
    padding: 5px 15px;
    border-radius: 5px;
    width: max-content;
    border: 2px solid var(--blue);
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
    color: white;
    background-color: var(--blue);
    font-size: 1.25rem;
    text-decoration: none;
    transition: background-color 0.15s linear, color 0.15s linear;
}

.blue-btn:hover {
    background-color: transparent;
    color: var(--blue);
}

h1 {
    font-family: "montserrat";
    font-size: 3rem; /* 4 x 16 = 64 */
    width: max-content;
    max-width: 100%;
    font-weight: 700;
}

h2 {
    font-family: "montserrat";
    font-size: 2.25rem;
    text-align: left;
    width: max-content;
    position: relative;
    padding-top: 30px;
    margin-top: 0; /* margin top is removed so grey sections in landing pages are spaced evenly on the top */
}
h2::after {
    content: "";
    height: 5px;
    background-color: var(--red);
    width: 75%;
    position: absolute;
    bottom: -5px;
    left: 0px;
    border-radius: 1px;
}

p {
    font-size: 1rem;
    margin: 0;
}

.standard-title {
    margin: 125px auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.standard-title h1 {
    margin: auto;
}

.standard-title span {
    font-size: 1.25rem;
    font-weight: 600;
    white-space: pre;
    width: 100vw;
}

.standard-title span a {
    text-decoration: underline solid transparent;
    color: var(--red);
    transition: text-decoration-color 0.2s linear;
}

.standard-title span a:hover {
    text-decoration-color: var(--red);
}

hr.post-title {
    width: 100%;
    border: 1px solid var(--black);
    max-width: 90vw;
}

@media only screen and (min-width: 70rem) {
    .section-content {
        /* for desktop sections under each header, content is indented/less wide */
        margin: 0 50px;
    }
}

@media only screen and (min-width: 90rem) {
    html {
        font-size: 20px; /* changes basis for rem */
    }
}