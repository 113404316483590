@import url("ListElement.css");

.element-name {
    font-weight: bold;
}

.element-value {
    font-weight: bold;
    color: #e83838;
}

.element-read {
    background-color: lightgreen;
}

.element-viewed {
    background-color: green;
}