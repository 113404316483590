@import "../globals.css";

.landing-header {
    background-color: var(--grey);
    padding: 0.25rem 0;
    position: relative;
    z-index: 1;
}

.landing-header .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1em;
    padding: 15px 0;
    margin: auto;
}

.landing-header img.logo {
    max-width: 70vw;
    height: 30px;
    /* cursor: pointer; */
}

.landing-header .links {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    min-width: 80vw;
}

.landing-header p.link {
    font-weight: 700;
    overflow: hidden;
    position: relative;
    padding: 5px 0;
    cursor: pointer;
}

.landing-header p.link::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.15rem;
    background-color: transparent;
    transition: background-color 0.2s linear;
}

.landing-header p.link:hover::after, .landing-header p.link.curr-page::after {
    background-color: var(--red);
}

.landing-header .blue-btn {
    font-size: 1rem;
    cursor: pointer;
}

@media only screen and (min-width: 25rem) {
    .landing-header .content {
        justify-content: space-between; /* spans full width when not wrap, breakpoint manually found */
    }

    .landing-header .content .links {
        flex-direction: row; /* row on desktop */
        gap: 50px;
        min-width: max-content;
    }
}
