.header-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header-container-behind {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  /* background: white; */
  /* border-bottom: 2px solid black; */
}

.header-screen-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(3px);
  /* mask-image: linear-gradient(to bottom, black 60%, transparent 100%); */
  /* border-bottom: 2px solid black; */
}

.company-info {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.company-name {
  margin-right: 20px;
  margin-left: 20px;
}

.logo {
  /* width: 250px; */
  height: 40px;
  float: left;
}

.logout-button {
  background-color: #e83838;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  /* margin-right: 20px; */
}

.go-button {
  background-color: #0c7ffa;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.header-spacer {
  height: 90px;
}

