.filter-dropdown {
    position: relative;
    display: flex;
    width: 10rem;
}

.filter-dropdown.Medics {
    width: 7.5rem;
}

.filter-dropdown.Read {
    width: 10rem;
}

.filter-dropdown.Finished {
    width: 12.25rem;
}

.filter-dropdown.Measures {
    width: 16rem;
}

.filter-dropdown .select-btn {
    background-color: white;
    width: 100%;
    border-radius: 5px;
    border: 2px solid var(--black);
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    font-family: var(--lato);
    display: flex;
    justify-content: space-between;
    /* border: none; */
}

.filter-dropdown .options-box {
    background-color: white;
    border-radius: 6px;
    border: 2px solid var(--black);
    font-size: 1rem;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0.5rem;
    width: 100%;
    overflow-y: auto;
    z-index: 99;
    position: absolute;
    top: 2.1rem;
    box-sizing: border-box;
    height: max-content;
    max-height: 0rem;
    visibility: hidden;
    transition: max-height 0.2s linear;
}

.filter-dropdown .options-box.open {
    max-height: 15rem;
    visibility: visible;
}

.filter-dropdown .select-btn .fa-angle-down {
    transition: transform 0.2s linear;
}

.filter-dropdown .select-btn[aria-expanded="true"] .fa-angle-down {
    transform: rotate(180deg);
}

.filter-dropdown .options-box::-webkit-scrollbar {
    width: 7px;
}

.filter-dropdown .options-box::-webkit-scrollbar-track {
    background: var(--grey);
    border-radius: 25px;
}

.filter-dropdown .options-box::-webkit-scrollbar-thumb {
/* background: var(--med-grey); */
border-radius: 25px;
}

.filter-dropdown .options-box .option {
    padding: 0.25rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    text-align: start;
}

.filter-dropdown .options-box .option input {
    accent-color: var(--black);
    height: 1rem;
    width: 1rem;
}

.filter-dropdown .options-box .select-ctrls {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.25rem;
    width: 100%;
    position: sticky;
    bottom: 0;
    background-color: white;
    border-top: 1px solid var(--black);
    padding: 0.25rem;
    font-size: 0.75rem;
}

.filter-dropdown .options-box .select-ctrls span {
    text-decoration: underline;
    color: var(--blue);
    cursor: pointer;
    transition: color 0.2s linear;
}

.filter-dropdown .options-box .select-ctrls span:hover {
    color: var(--black);
}