.faq-entries {
    margin: 50px auto;
    margin-bottom: 100px;
}

.faq-page {
    width: 100vw;
}

.faq-entries .question {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    font-weight: 700;
    font-size: 1.25rem;
    text-align: left;
}

.faq-entries .question p {
    font-size: 1.25rem;
}

.faq-entries .question i {
    height: max-content;
    transform-origin: center;
    transition: transform 0.2s linear;
}

.faq-entries .question i.open {
    transform: rotate(180deg);
}

.faq-entries .answer {
    text-align: left;
    height: max-content;
    max-height: 0rem;
    transition: max-height 0.2s linear;
    overflow: hidden;
}

.faq-entries .answer.open {
    max-height: 5rem;
    height: max-content;
}

.faq-entries .answer p {
    margin-bottom: 0.75rem;
}

.qna {
    cursor: pointer;
}