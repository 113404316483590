@import "../../globals.css";

.landing-about {
    display: none;
    margin: 50px auto;
}

.landing-about .content {
    align-items: flex-start;
}


.landing-about p {
    text-align: left;
}

