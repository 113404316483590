/* 
For Montserrat, you can just use `font-family: montserrat' 
and `font-family: montserrat-italic' then specify 
font-weight to be anything from 100 to 900
 
But for Lato, you need to use the custom classes
ie lato, lato-italic, lato-black-italic, etc
*/

@font-face {
    font-family: 'blade-runner';
    src: url('./BLADRMF_.TTF') format('truetype');
}

@font-face {
    font-family: 'ahroni';
    src: url('./ahronbd.ttf') format('truetype');
}

@font-face {
    font-family: 'montserrat';
    src: url('./Montserrat/Montserrat-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'montserrat-italic';
    src: url('./Montserrat/Montserrat-Italic-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'lato';
    src: url('./Lato/Lato-Regular.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-italic';
    src: url('./Lato/Lato-Italic.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-black';
    src: url('./Lato/Lato-Black.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-black-italic';
    src: url('./Lato/Lato-BlackItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-bold';
    src: url('./Lato/Lato-Bold.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-bold-italic';
    src: url('./Lato/Lato-BoldItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-light';
    src: url('./Lato/Lato-Light.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-light-italic';
    src: url('./Lato/Lato-LightItalic.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-thin';
    src: url('./Lato/Lato-Thin.ttf') format("truetype");
}

@font-face {
    font-family: 'lato-thin-italic';
    src: url('./Lato/Lato-ThinItalic.ttf') format("truetype");
}
