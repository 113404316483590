.filter-list-element {
    /* background-color: var(--med-grey); */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
}

.filter-list-element .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.filter-list-element .row .filters {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    gap: 0.75rem;
    flex-wrap: wrap;
}

.filter-list-element .row .submit {
    display: flex;
    align-items: center;
    background-color: var(--blue);
    color: white;
    border: 2px solid var(--blue);
    border-radius: 5px;
    padding: 0.5rem 0.5rem;
    font-size: 1rem;
    /* font-weight: 700; */
    margin-left: auto;
}

.filter-list-element hr {
    border: 1px solid var(--black);
    width: 100%;
    border-radius: 5px;
}

.filter-list-element .applied-tags {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.filter-list-element .applied-tags .tags, .list-element .applied-tags .medics {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.filter-list-element .applied-tags .medics .medic-tag {
    background-color: var(--dark-grey);
    color: white;
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    border-radius: 5px;
    font-weight: 700;
}

.only-i {
    font-size: 1.75rem;
    /* color: var(--red); */
}