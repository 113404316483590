@import "../globals.css";

footer {
    background-color: var(--grey);
    /* margin-top: 100px; */
}

footer .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.75em;
    padding: 25px 0;
}

footer .icons {
    display: flex;
    align-items: center;
    gap: 30px;
}

footer .icons a {
    text-decoration: none;
    color: var(--black);
    font-size: 1.5em;
}

footer .icons i {
    transition: color 0.15s linear
}

footer .icons i:hover {
    color: var(--blue);
}

@media only screen and (min-width: 35rem) {
    footer .content {
        justify-content: space-between; /* footer spans full width when not wrap, breakpoint manually found */
    }
}