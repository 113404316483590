.list-element {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: var(--light-grey);
    border-radius: 5px;
    margin-bottom: 10px;
    /* border: 1px solid var(--dark-grey); */
}

.list-header {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-size: larger;
    background-color: var(--dark-grey);
    color: white;
}

.tiny-div {
    flex: 0.4;
    display: flex;
    justify-content: flex-start;
}

.small-div {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.medium-div {
    flex: 1.8;
    display: flex;
    justify-content: flex-start;
}

.large-div {
    flex: 3;
    display: flex;
    justify-content: flex-start;
}