.footer {
    width: 100%;
    text-align: center;
    padding: 2px;
    background-color: #eee;
    border-top: 1px solid #ddd;
}

.footer-sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}