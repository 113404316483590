/* Base style for the tag component */
.tag {
    display: inline-block;    /* Ensure the tag fits around the content */
    border-radius: 3px;       /* Set the border radius */
    padding: 1px 4px;         /* Set the padding (you can adjust the horizontal padding if needed) */
    color: white;           /* Set the text color */
    margin-right: 2px;        /* Set the right margin to 2px */
    font-size: 12px;          /* Default font size, can be adjusted */
    font-weight: bold;        /* Default font weight, can be adjusted */
    text-align: center;       /* Center the text within the tag */
    white-space: nowrap;      /* Prevent text from wrapping */
}

/* button class that appears identical to .tag when employed */
.tag-button {
    border: none;             /* Remove the border */
    background: none;         /* Remove the background */
    color: inherit;           /* Inherit the color */
    font: inherit;            /* Inherit the font */
    cursor: pointer;          /* Change the cursor to a pointer */
    padding: 1px 4px;         /* Set the padding (you can adjust the horizontal padding if needed) */
    color: white;           /* Set the text color */
    margin-right: 2px;        /* Set the right margin to 2px */
    outline: none;            /* Remove the outline */
    border-radius: 3px;       /* Set the border radius */
    font-size: 12px;          /* Default font size, can be adjusted */
    font-weight: bold;        /* Default font weight, can be adjusted */
    text-align: center;       /* Center the text within the tag */
    white-space: nowrap;      /* Prevent text from wrapping */
}


/* Exclusive Types - no fill, border and text same color */
.non-emergent {
    background-color: #fff;
    color: #1a6cc9;
    border: 1px solid #1a6cc9;
}

.non-acute {
    background-color: #fff;
    color: #22b922;
    border: 1px solid #22b922;
}

.refusal {
    background-color: #fff;
    color: #E78D0C;
    border: 1px solid #E78D0C;
}

.cancelled {
    background-color: #fff;
    color: var(--dark-grey);
    border: 1px solid var(--dark-grey);
}

.general-emergent {
    background-color: #fff;
    color: #e83838;
    border: 1px solid #e83838;
    
}


/* Modifier Types - fill colored, text to contrast*/
.stroke {
    background-color: #4CC9F0; 
    color: var(--dark-grey);
}

.cardiac {
    background-color: #F72585; 
    color: var(--dark-grey);
}

.arrest {
    background-color: #E83838;
}

.stemi {
    background-color: #7209B7;
}

.trauma {
    background-color: #000;
}

.respiratory {
    background-color: #f9f239;
    color: var(--dark-grey);
}

.seizure {
    background-color: #015f10; 
}

.safety {
    background-color: #916868;
}

.syncope {
    background-color: #32CD32; 
    color: var(--dark-grey);
}

.tbi {
    background-color: #555; 
}

.pediatrics {
    background-color: #F08080;
    color: var(--dark-grey);
}

.hypoglycemia {
    background-color: #89eca5;
    color: var(--dark-grey);
}

.airway {
    background-color: #FBA203 ;
    color: var(--dark-grey);
}

.asthma {
    background-color: #A84000;
}

.narcotics {
    background-color: #B0E0E6;
    color: var(--dark-grey);
}

.high-risk-refusal {
    background-color: #e6b54c;
    color: var(--dark-grey);
}

.ground-transport {
    background-color: #1a6cc9;
    color: var(--dark-grey);
}

.ai-flag {
    background-color: #ff13f0;
    /* color: var(--dark-grey); */
}

.narrative-suggestions {
    background-color: #ff13f0;
    /* color: var(--dark-grey); */
}

.suggestions {
    background-color: #ca29bf;
    /* color: var(--dark-grey); */
}

.protocol-flag {
    background-color: #ff13f0;
    /* color: var(--dark-grey); */
}


/* Name Tags - black border, simple color text*/
.medic {
    background-color: #fff;
    color: var(--dark-grey);
    border: 1px solid var(--dark-grey);
    border-radius: 0;
}

.author {
    background-color: #fff;
    color: #E83838;
    border: 1px solid var(--dark-grey);
    border-radius: 0;
}


/* Issue Types */
.billing {
    background-color: #fff;
    color: #109629;
    border: 1px solid var(--dark-grey);
}

.documentation {
    background-color: #fff;
    color: #1a38ce;
    border: 1px solid var(--dark-grey);
}

.patient-care {
    background-color: #fff;
    color: #d31717;
    border: 1px solid var(--dark-grey);
}

.measure {
    background-color: #fff;
    color: #d31717;
    border: 1px solid var(--dark-grey);
}

.quant-measure {
    background-color: #fff;
    color: #d31717;
    border: 1px solid var(--dark-grey);
}