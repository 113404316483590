.about-page {
    margin: auto;
}

.about-page .about-banner{
    background-image: linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url("https://images.unsplash.com/photo-1600959907703-125ba1374a12?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 175px 0;
    background-position-y: 50%;
    background-position-x: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    margin: auto;
}

.about-page .about-banner h1 {
    color: var(--red);
    font-weight: 500;
    margin: auto;
}

.about-page .about-banner p {
    color: white;
}